import React from "react";
import { Link } from "gatsby";
import { Layout } from "../../components";
import Slider from "react-slick";

const BaneryReklamowe = ({ location: { pathname } }) => {
  const settings = {
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 3,
    draggable: true,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 980,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Layout
      seo={{
        title: "BaneryReklamowe",
        href: pathname,
        lang: "pl",
      }}
      langSwitch={{
        langKey: "en",
        langSlug: "/en/offer/banner-ads/",
      }}
    >
      <section className="container-fluid google_ads_section">
        <div className="text-center">
          <h1>Banery reklamowe</h1>
        </div>
        <div className="row social_row">
          <div className="col-md-10 m-auto">
            <p className="text-center">
              Banery reklamowe pozwalają odbiorcom zapoznać się z danym brandem,
              budują świadomość firmy a dzięki banerom remarketingowym skłaniamy
              klientów do ponownego odwiedzenia strony www. Kampanie tego typu
              kierowane są do użytkowników, którzy na ekranie komputera lub
              urządzeń mobilnych przeglądali wcześniej Twoją stronę www albo
              poszukiwali produktu w sklepie internetowym. W tym przypadku baner
              jest próbą nawiązania kolejnego kontaktu z Twoim potencjalnym
              klientem i zachęceniem do dokończenia transakcji lub dokonania
              innego, ważnego dla Ciebie celu. Ale to nie wszystko. Banery
              reklamowe w różnych rozmiarach oraz w wersjach statycznych lub
              animowanych możemy wyświetlać użytkownikom, którzy przeglądają
              strony o konkretnej tematyce lub docierając do nich na podstawie
              ich zainteresowań i wcześniejszych zachowań w sieci.
            </p>
            <p className="text-center">
              Warto pamiętać jak wielki potencjał ma remarketing banerowy – o
              swoich usługach możesz przypominać użytkownikom aż przez 540 dni!
            </p>

            <div className="text-center">
              <img
                className="img-fluid"
                src={require("../../assets/img/offer/google.png")}
                alt=""
              />
            </div>
          </div>
        </div>
        <h3>Jak konfigurujemy banery reklamowe?</h3>
        <div className="row boxes_row">
          <div className="col-lg-4 col_1">
            <div className="inner_box">
              <span>1.</span>
              <p>
                Najpierw pracujemy nad hasłem reklamowym. Jego myśl przewodnia
                musi koniecznie odzwierciedlać wartość produktu / usługi.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col_2">
            <div className="inner_box">
              <span>2.</span>
              <p>
                Następnie przygotowujemy wszystkie niezbędne elementy graficzne.
                Ważne są: proporcjonalne rozmieszczenie elementów graficznych,
                nowoczesny styl identyfikacji strony oraz zgodna ze sztuką
                typografia i estetyczne umiejscowienie tekstów.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col_3">
            <div className="inner_box">
              <span>3.</span>
              <p>
                Upewniamy się, że podane informacje są zrozumiałe dla
                użytkownika, który bez wahania powinien odpowiedzieć na pytanie
                co reklamuje dany baner. Wyjątkiem jest kampania wizerunkowa,
                której celem jest przykucie uwagi użytkownika.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col_4">
            <div className="inner_box">
              <span>4.</span>
              <p>
                Wybieramy odpowiedni przycisk call to action (wezwania do
                działania) np. “Sprawdź” lub “Kup teraz”. CTA ma na celu
                zachęcać do działania np. zakupu produktu.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col_5">
            <div className="inner_box">
              <span>5.</span>
              <p>Umieszczamy logo Twojej firmy na banerze</p>
            </div>
          </div>
        </div>
        <h3>Zobacz nasze realizacje</h3>
        <div className="container-fluid">
          <Slider {...settings} className="banners-grid">
            <div className="banners-grid-slide__image">
              <iframe
                src="https://wp.adream.pl/wp-content/themes/adream/assets/banery/aparthotel_boze_narodzenie/index.html"
                width="300"
                height="600"
                title="ApartHotel Boże Narodzenie"
              ></iframe>
            </div>
            <div className="banners-grid-slide__image">
              <iframe
                src="https://wp.adream.pl/wp-content/themes/adream/assets/banery/aparthotel_jesien/index.html"
                width="300"
                height="600"
                title="ApartHotel Jesień"
              ></iframe>
            </div>
            <div className="banners-grid-slide__image">
              <iframe
                src="https://wp.adream.pl/wp-content/themes/adream/assets/banery/bialaperla/index.html"
                width="300"
                height="600"
                title="Biała Perła"
              ></iframe>
            </div>
            <div className="banners-grid-slide__image">
              <iframe
                src="https://wp.adream.pl/wp-content/themes/adream/assets/banery/czarnaperla_rower/index.html"
                width="300"
                height="600"
                title="Czarna Perła"
              ></iframe>
            </div>
            <div className="banners-grid-slide__image">
              <iframe
                src="https://wp.adream.pl/wp-content/themes/adream/assets/banery/domki_chorwacja/index.html"
                width="300"
                height="600"
                title="Domki Chorwacja"
              ></iframe>
            </div>
            <div className="banners-grid-slide__image">
              <iframe
                src="https://wp.adream.pl/wp-content/themes/adream/assets/banery/fitizzio/index.html"
                width="300"
                height="600"
                title="Fitizzio"
              ></iframe>
            </div>
            <div className="banners-grid-slide__image">
              <iframe
                src="https://wp.adream.pl/wp-content/themes/adream/assets/banery/ggp_evolution/index.html"
                width="300"
                height="600"
                title="GGP Evolution"
              ></iframe>
            </div>
            <div className="banners-grid-slide__image">
              <iframe
                src="https://wp.adream.pl/wp-content/themes/adream/assets/banery/ibis/index.html"
                width="300"
                height="600"
                title="Ibis"
              ></iframe>
            </div>
            <div className="banners-grid-slide__image">
              <iframe
                src="https://wp.adream.pl/wp-content/themes/adream/assets/banery/ibis_mercure/index.html"
                width="300"
                height="600"
                title="Ibis Mercure"
              ></iframe>
            </div>
            <div className="banners-grid-slide__image">
              <iframe
                src="https://wp.adream.pl/wp-content/themes/adream/assets/banery/mielnomolo_300x600/index.html"
                width="300"
                height="600"
                title="Mielno Molo"
              ></iframe>
            </div>
            <div className="banners-grid-slide__image">
              <iframe
                src="https://wp.adream.pl/wp-content/themes/adream/assets/banery/nma/index.html"
                width="300"
                height="600"
                title="NaprawMiAuto"
              ></iframe>
            </div>
            <div className="banners-grid-slide__image">
              <iframe
                src="https://wp.adream.pl/wp-content/themes/adream/assets/banery/szczawnica_stara/index.html"
                width="300"
                height="600"
                title="Szczawnica"
              ></iframe>
            </div>
            <div className="banners-grid-slide__image">
              <iframe
                src="https://wp.adream.pl/wp-content/themes/adream/assets/banery/virego/index.html"
                width="300"
                height="600"
                title="Virego"
              ></iframe>
            </div>
          </Slider>
        </div>
        <div className="baners_wrapper">
          <img
            className="img-fluid"
            src={require("../../assets/img/offer/remarketing_baners.png")}
            alt=""
          />
        </div>
      </section>

      <section className="container-fluid section_services">
        <div className="text-center">
          <h2>Wszystkie usługi</h2>
        </div>
        <div className="row">
          <div className="col-md-2 box">
            <span>Analityka internetowa</span>
            <Link to="/oferta/analityka-internetowa/">zobacz</Link>
          </div>
          <div className="col-md-2 box">
            <span>Social Media</span>
            <Link to="/oferta/social-media/">zobacz</Link>
          </div>
          <div className="col-md-2 box">
            <span>Linkedin Ads</span>
            <Link to="/oferta/linkedin-ads/">zobacz</Link>
          </div>
          <div className="col-md-2 box">
            <span>Facebook Ads</span>
            <Link to="/oferta/facebook-ads/">zobacz</Link>
          </div>
          <div className="col-md-2 box">
            <span>Google Ads</span>
            <Link to="/oferta/ads-google/">zobacz</Link>
          </div>
          <div className="col-md-2 box">
            <span>Kampanie zagraniczne</span>
            <Link to="/oferta/kampanie-zagraniczne/">zobacz</Link>
          </div>
        </div>
      </section>
    </Layout>
  );
};
export default BaneryReklamowe;
